/**
 * @Author Liu Yin
 * @Date 2021/11/19
 * @Description: 字典内容转化
 */
import * as module from './index'

/**
 * 根据字典的值和字典对象获取字典对应的名称
 * @param value 需要转化的值
 * @param dict 字典对象或者字典对象的字符串名称
 * @returns { String } 字典值对应的NAME
 */
export function convertDict(value, dict) {
  const dictObj = typeof dict === 'string' ? module[dict] : dict
  if (!dictObj.NAMES) { return value }
  const keyArr = Object.keys(dictObj)
  const index = keyArr.findIndex((key) => {
    return String(dictObj[key]) === String(value)
  })
  if (index === -1) {
    return value
  }
  return dictObj.NAMES[index]
}

/**
 * 将字典对象转化为list
 * @param dict 需要转化的字典对象或对象的字符串名称
 * @returns Array [{key:'KEY',value:1,name:'名称'}]
 */
export function dictToArr(dict) {
  const dictObj = typeof dict === 'string' ? module[dict] : dict
  const array = []
  const names = dictObj.NAMES
  Object.keys(dictObj).forEach((key, index) => {
    if (key === 'NAMES') { return }
    array.push({
      key,
      value: dictObj[key],
      name: names[index]
    })
  })
  return array
}

export default module
