<template>
  <div class="flexcenbet">
    <div class="left_box">
      <ul class="personal-menu">
        <li v-for="(item,index) in personMenus" :key="index" class="person-item" :class="{'active-navigate':displayBlock===item.id}" @click="handlePersonMenu(item)">{{ item.name }}</li>
      </ul>
    </div>
    <div class="right_box">
      <my-course v-if="displayBlock===1"></my-course>
      <my-evaluation v-if="displayBlock===2"></my-evaluation>
    </div>
  </div>
</template>
<script>
import myCourse from './components/myCourse.vue'
import myEvaluation from './components/myEvaluation.vue'
export default {
  name: 'Personal',
  components: {
    myCourse,
    myEvaluation
  },
  data() {
    return {
      personMenus: [
        {
          id: 1,
          name: '我的课程'
        },
        {
          id: 2,
          name: '我的测评'
        }
      ],
      displayBlock: 1
    }
  },
  mounted() {

  },
  methods: {
    handlePersonMenu(item) {
      this.displayBlock = item.id
    }
  }
}
</script>
<style lang="scss" scoped>
.flexcenbet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.left_box {
  width: 20%;
  display: inline-block;
  margin-right: 2%;
}
.right_box {
  width: 78%;
  display: inline-block;
}
.personal-menu {
  background: #fff;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 100%;
}
.person-item {
  padding: 16px 20px;
  display: block;
  cursor: pointer;
  font-size: 15px;
  color: #505252;
}
.person-item:hover {
  color: #f5a724;
  background: rgba(245, 167, 36, 0.1);
  .svg-icon {
    color: #f5a724;
  }
}
.active-navigate {
  color: #f5a724;
  background: linear-gradient(84deg, #ffedd0, rgba(255, 226, 178, 0.06));
  .svg-icon {
    color: #f5a724;
  }
}
</style>
