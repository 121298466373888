<template>
  <div class="servicePage">
    <div class="podire" @mouseover.stop="QRFlag=true" @mouseleave.stop="QRFlag=false">
      <div class="posifix">
        <div class="customerpage">
          <i class="el-icon-service"></i>
        </div>
      </div>
      <div class="QRcode" :class="[ QRFlag ? '' : 'none' ]">
        <div>
          <img :src="getImageSrc()" alt="" class="img_size" @error="handleError">
        </div>
        <div>请扫描二维码</div>
        <div>联系客服咨询</div>
      </div>
    </div>
    <div class="podire" @mouseover.stop="QRFlag=true" @mouseleave.stop="QRFlag=false">
      <div class="posifix">
        <div class="helpDiv">
          <span>帮助中心</span>
        </div>
      </div>
      <!-- <div class="QRcode" :class="[QRFlag?'':'none']">
        <div>
          <img :src="parkData.parkCustQrCode" alt="" class="img_size">
        </div>
        <div>微信请扫描二维码</div>
        <div>联系客服咨询</div>
      </div> -->
    </div>

  </div>
</template>
<script>
import QRCode from '../../assets/images/customerQR.png'
export default {
  data() {
    return {
      QRFlag: false
    }
  },
  computed: {
    parkData() {
      return this.$store.getters.parkData
    }
  },
  methods: {
    getImageSrc() {
      if (!this.parkData.parkCustQrCode) {
        return QRCode
      } else {
        return this.parkData.parkCustQrCode
      }
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = QRCode
      img.onerror = null // 防止闪图
    }
  }

}
</script>
<style lang="scss" scoped>
.servicePage {
  width: 100%;
}
.posifix {
  position: fixed;
  right: 0px;
  cursor: pointer;
}
.podire {
  position: relative;
  cursor: pointer;
}
.QRcode::before {
  content: "";
  height: 200px;
  width: 180px;
  position: absolute;
  right: -35px;
  bottom: 0;
}
.customerpage {
  width: 100%;
  background: #2c6efb;
  padding: 5px 3px 5px 5px;
  border-radius: 4px 0 0 4px;
}
.el-icon-service:before {
  // content: "\e6d4";
  color: #fff;
}
.helpDiv {
  width: 15px;
  margin-top: 40px;
  background: #2c6efb;
  padding: 8px 16px 8px 7px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
}
.QRcode {
  background: #fff;
  position: absolute;
  margin: 0 auto;
  right: 35px;
  bottom: -95px;
  padding: 24px 24px 12px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  color: #86909c;
  border-radius: 4px;
}
.img_size {
  width: 120px;
  height: 120px;
}
.none {
  display: none;
}
</style>
