<!--
   User: Liu Yin
   Date: 2020/2/27
   Description:
 -->
<template>
  <article>
    <section>
      <!-- <main-header></main-header> -->
      <navigation-bar></navigation-bar>
      <section class="page-content">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" class="router-container" />
          </keep-alive>
        </transition>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <broad-side class="customer_page"></broad-side>
      </section>
    </section>
    <page-bottom class="pagebottom"></page-bottom>
  </article>
</template>

<script>
// import mainHeader from '../components/header'
import broadSide from './components/broadSide'
import pageBottom from './components/page-bottom'
import navigationBar from './components/navigationBar'

export default {
  components: {
    // mainHeader,
    pageBottom,
    navigationBar,
    broadSide
  },
  data() {
    return {
      parkData: {}
    }
  },
  created() {
    this.getDetailData()
  },
  methods: {
    // 获取园区信息
    getDetailData() {
      this.$get(this.urls.getParkInfo, {}, [1]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.$store.dispatch('app/setParkData', data)
        } else {
          this.$message.error('查询失败')
        }
      }).catch(() => {
        this.$message.error('查询失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.customer_page {
  position: fixed;
  right: 0px;
  bottom: 174px;
  z-index: 99;
}
.page-content{
background-color: #f5f6f8;
// min-height: 800px;
min-height: 100vh;
}
</style>
