<template>
  <div>
    <svg viewBox="0 0 100 100">
      <linearGradient :id="`gradient${index}`" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop :stop-color="startColor" offset="0" />
        <stop :stop-color="endColor" offset="100%" />
      </linearGradient>
      <use :href="iconName" :fill="`url(#gradient${index})`" />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'GradientVerticalIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    startColor: {
      type: String,
      default: ''
    },
    endColor: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    }
  },
  watch: {
    startColor(val1, val2) {
      console.log('val1==', val1, 'val2==', val2)
    }
  }
}
</script>

<style scoped>

</style>
