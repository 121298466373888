/**
 * @author Liu Yin
 * @date 2020/1/16
 * @Description: 全局属性和函数的声明
*/

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { $post, $get, $postForm } from './request'
import urls from './urls'
import { toggleLoading, clone, downloadFile, formattingField } from '../utils/tools'
import floatLabel from '../components/custom/floatLabel'
import returnKey from '../components/custom/returnKey'
import dict, { convertDict, dictToArr } from './dict/convert'

import moment from 'moment'
// 格式化时间
const formattingTime = function (value, format = 'YYYY-MM-DD') {
  if (!value) return ''
  return moment(value).format(format)
}
const majorCity = '江苏'
export default {
  install (Vue) {
    majorCity
    Vue.prototype.$majorCity = majorCity
    Vue.prototype.$post = $post
    Vue.prototype.$get = $get
    Vue.prototype.$postForm = $postForm
    Vue.prototype.urls = urls
    Vue.prototype.toggleLoading = toggleLoading
    Vue.prototype.formattingField = formattingField
    Vue.prototype.clone = clone
    Vue.prototype.downloadFile = downloadFile
    Vue.component('swiper', swiper)
    Vue.component('swiperSlide', swiperSlide)
    Vue.component(floatLabel.name, floatLabel)
    Vue.filter('formattingTime', formattingTime)
    Vue.component(returnKey.name, returnKey)

    // /utils/dict/index.js文件定义的字典对象
    Vue.prototype.$dict = dict
    // 将字典对象转化为数组
    Vue.prototype.$dictToArr = dictToArr

    // 根据字典value值转化为对应的名称
    Vue.filter('convert', convertDict)
  }
}
