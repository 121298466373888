<!--
   User: Liu Yin
   Date: 2020/3/19
   Description:
 -->
<template>
  <article>
    <section v-if="!token" class="login-select-parent">
      <span class="login-type" @click="toLogin('2')">
        <i class="el-icon-user"></i>登录</span>
      <a class="registerfont" :href="linkRegister" target="_blank">
        <div class="register">
          注册
        </div>
      </a>
    </section>
    <section v-else>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link" style="cursor: pointer">
          <!-- <i style="font-size: 17px" class="el-icon-user"></i>
          <span style="margin-left: 10px;font-size: 14px;display:inline-block;width: 140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;line-height: 16px;" :title="user.enterName+'    '+user.peopleName">{{ user.enterName }}&nbsp;&nbsp;{{ user.peopleName }}</span>
          <i style="font-size: 17px" class="el-icon-arrow-down el-icon--right"></i> -->
          <el-button type="primary">管理中心</el-button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item icon="el-icon-s-custom" @click.native="personalCenter">个人中心</el-dropdown-item> -->
          <el-dropdown-item icon="el-icon-s-promotion" @click.native="toManger">管理中心</el-dropdown-item>
          <el-dropdown-item icon="el-icon-s-operation" @click.native="logout(2)">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </section>
    <el-dialog title="个人中心" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <personal></personal>
    </el-dialog>

  </article>
</template>

<script>
import { getToken, getUserInfo, removeToken, removeUserInfo } from '../../utils/auth'
import Cookies from 'js-cookie'
import personal from '../../views/pc/main/personal/index.vue'
export default {
  components: { personal },
  data() {
    return {
      token: getToken(),
      showToEnterprise: false,
      showToPark: false,
      showEnterpriseCert: false,
      // 用户角色
      userRole: '',
      dialogVisible: false,
      linkRegister: process.env.VUE_APP_ENTERPRISE_URL + '/register'
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.userInfo
      },
      set() {
      }
    }
  },
  watch: {
    user() {
      this.getUserRole()
    }
  },
  mounted() {
    this.token = getToken()
    if (!this.user) this.user = getUserInfo()
    if (this.user) {
      this.getUserRole()
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    personalCenter() {
      this.dialogVisible = true
    },
    getUserRole() {
      const peopleType = this.user.peopleType
      const level = this.user.enterLevel
      if (peopleType === '1') {
        // 园区系统用户
        this.showToPark = true
      }
      if (peopleType === '2') {
        // 企业用户
        if (level === '1') {
          // 注册企业用户
          this.showEnterpriseCert = true
        } else {
          // 认证企业
          this.showToEnterprise = true
        }
      }
    },
    async logout(type) {
      this.toggleLoading(true)
      await this.$get(this.urls.logout)
      this.toggleLoading(false)
      this.toLogin(type)
    },
    toLogin(type) {
      // if (type === '2') {
      // window.open(`${location.origin}/corp/login/`)
      //   // window.open(window.location.origin + '/corp')

      //   // this.$router.push('/login/loginEnterprise')
      // } else {
      this.$router.push('/login')
      // }
      removeToken()
      removeUserInfo()
      this.$store.dispatch('app/setLoginType', type)
    },
    toManger() {
      const jump = Number(this.user.peopleType)
      console.log(jump === 2)
      if (jump === 1) {
        Cookies.set('enterprisetoken', null)
        Cookies.set('managementtoken', null)
        window.localStorage.removeItem('managementuser')
        window.localStorage.removeItem('mutual-user')
        window.localStorage.removeItem('navigation')
        this.toPark()
      }
      if (jump === 2) {
        Cookies.set('enterprisetoken', null)
        Cookies.set('managementtoken', null)
        window.localStorage.removeItem('managementuser')
        window.localStorage.removeItem('mutual-user')
        window.localStorage.removeItem('navigation')
        this.toEnterprise()
      }
      if (jump === 3) {
        Cookies.set('enterprisetoken', null)
        Cookies.set('managementtoken', null)
        window.localStorage.removeItem('managementuser')
        window.localStorage.removeItem('mutual-user')
        window.localStorage.removeItem('navigation')
        Cookies.set('enterprisetoken', this.token)
        const link = process.env.VUE_APP_ENTERPRISE_URL
        // window.open(link + '/personcenter/personinfo?enterprisetoken=' + encodeURIComponent(this.token))
        window.open(link + '/personcenter/personinfo')
      }
    },
    // 进入园区后台
    toPark() {
      Cookies.set('managementtoken', this.token)
      const url = process.env.VUE_APP_PARK_URL
      // window.open(url + '?managementtoken=' + encodeURIComponent(this.token))
      window.open(url)
    },
    // 进入企业后台
    toEnterprise() {
      Cookies.set('enterprisetoken', this.token)
      const enterpriseUrl = process.env.VUE_APP_ENTERPRISE_URL
      console.log(enterpriseUrl)
      // window.open(enterpriseUrl)
      // window.open('http://192.168.100.150:3007/corp/personcenter/personinfo?' + 'token=' + encodeURIComponent(this.token))
      // window.open(enterpriseUrl + '?enterprisetoken=' + encodeURIComponent(this.token))
      window.open(enterpriseUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-select-parent {
 display: flex;
    align-items: center;
    justify-content: space-between;
      margin: -8px 0px;
      max-width: 200px;
  .login-type {
    font-size: 16px;
    letter-spacing: 5px;
        margin-right: 20px;
    cursor: pointer;
    color: #565656;
  }
  .login-type:hover {
    color: #00074a;
  }
  .focusType {
    color: #00074a;
  }
}
.change-account {
  color: #00074a;
  cursor: pointer;
}
.register{
width: 104px;
    height: 32px;
    line-height: 32px;
    background: #3369ff;
    border-radius: 1px;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    cursor: pointer;
}
.registerfont{
  color: #fff;
}
</style>
