<template>
  <div>
    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" element-loading-text="数据资源加载中...">
      <el-table-column prop="createTime" label="测评时间">
      </el-table-column>
      <el-table-column prop="asName" label="测评名称">
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <!-- <template slot-scope="{row}">
          <el-button v-if="rowData.apStatus===3&&rowData.reviewStatus==='1'" type="text" size="small" @click="handleEdit(row)">查看报告</el-button> -->
        <template slot-scope="{row}">
          <el-button type="text" size="small" @click="showReport(row)">查看报告</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pageAction" :current-page.sync="currentPage" :page-size="size" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
  </div>
</template>
<script>
import listMixin from '@/utils/mixins/listMixin'
export default {
  name: 'MyEvaluation',
  components: {
  },
  mixins: [listMixin],
  data() {
    return {
      tableData: [],
      asId: 17
    }
  },
  mounted() {
    this.requestData()
  },
  methods: {
    showReport(item) {
      window.open(`${item.fileUrl}?attname=${item.name}`)
      // this.getDetali(item)
    },
    // getDetali(item) {
    //   this.$get(this.urls.personAssessDetail, {}, [item.apId]).then(result => {
    //     if (result.code === 'SUCCESS') {
    //       this.detailData = result.data
    //       this.downloadFile(item.fileUrl, item.asName)
    //     } else {
    //       this.$message.error(result.msg)
    //     }
    //   })
    // },
    requestData() {
      this.$get(this.urls.fetchLastFiveRecords + '/' + this.asId).then(result => {
        if (result.code === 'SUCCESS') {
          this.tableData = result.data
        }
      }).catch((e) => {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
