import Vue from 'vue'
import SvgIcon from '../components/SvgIcon'
import GradientVerticalIcon from '../components/SvgIcon/gradient-vertical-icon'

Vue.component('svg-icon', SvgIcon)
Vue.component(GradientVerticalIcon.name, GradientVerticalIcon)

const req = require.context('./svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
