<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:底部页面
 -->
<template>
  <article>
    <!--<p class="login-container">
      <span>加入天河科技园，成就卓越企业高度</span>
      <a @click="handleLogin">点击加入>></a>
    </p>
    <div style=" background: rgb(237,238,239);">
      <service-description style="margin: 0 25%"></service-description>
      <div class="line"></div>
    </div>-->
    <bottom-navigate></bottom-navigate>
  </article>
</template>

<script>
import bottomNavigate from '../../components/bottom-navigate'
// import serviceDescription from '../../components/custom/serviceDescription'
export default {
  components: {
    bottomNavigate
    // serviceDescription
  },
  data() {
    return {}
  },
  methods: {
    handleLogin() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  .line{
    background-color: #d2d3d4;
    height: 1px;
    border: none;
    margin-top: 30px;
    margin-left: 13%;
    margin-right: 13%;
  }
.login-container{
    line-height: 80px;background: rgb(237,238,239);text-align: center;
    span{
        font-size: 20px
    }
    a{
        color: red;margin-left: 20px;font-size: 20px;cursor: pointer
    }
}
</style>
