<!--
   User: Liu Yin
   Date: 2020/4/22
   Description:
 -->
<template>
  <span class="back" @click="handleBack">
    <svg-icon icon-class="back" class="back-svg"></svg-icon>
    <span>返回</span>
  </span>
</template>

<script>
export default {
  name: 'ReturnKey',
  data() {
    return {}
  },
  methods: {
    handleBack() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  .back-svg {
    font-size: 22px;
  }
  span {
    margin-left: 5px;
    line-height: 30px;
    font-size: 20px;
  }
}
.back:hover {
  color: rgb(0, 198, 154);
}
</style>
