<!--
   User: Liu Yin
   Date: 2020/3/30
   Description:
 -->
<template>
  <div id="changediv" class="changediv" style="">
    <article id="navigate-bar" class="navigate-bar">
      <!-- <section class="navigate-bg"></section> -->

      <section class="navigate-container logo">
        <!-- <svg-icon icon-class="logo"></svg-icon> -->
        <div class="logo">
          <!-- <img src="../../../public/logo4.png" alt=""> -->
          <img :src="parkData.parkLogo">
        </div>
        <!-- <ul class="navigate">
        <li v-for="item in menuList" :key="item.path" style="position: relative" :class="[currentFocus===item.meta.module?'navigate-focus':'navigate-un-focus',item.path,item.name]" class="navigate-item-parent" @click="clickNavigate(item)">
          <div class="navigate-item">{{ item.meta.module }}</div>
        </li>
      </ul> -->
      </section>
      <div class="flexcencen" style="position: relative;">
        <div :class="islong?'long':''" style="position: absolute;right: 180px;">
          <el-input id="input" v-model="input4" clearable placeholder="请输入搜索内容" @blur="lostFocus" @focus="gainFocus" @clear="clearFun">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <main-header-login class="loginInlet"></main-header-login>
      </div>
    </article>
    <ul id="navigate" class="navigate">
      <li v-for="item in menuList" :key="item.path" :class="[currentFocus===item.meta.module?'navigate-focus':'navigate-un-focus',item.path,item.name]" class="navigate-item-parent" @click="clickNavigate(item)">
        <div class="navigate-item">{{ item.meta.module }}</div>
      </li>
    </ul>
  </div>

</template>

<script>
import { mainRouter } from '../../router'
import { getToken } from '../../utils/auth'
import mainHeaderLogin from '../../components/custom/main-header-login'
export default {
  components: {
    mainHeaderLogin
  },
  data() {
    return {
      menuList: [],
      preRoute: mainRouter.path,
      hoverName: '',
      input4: '',
      islong: false,
      isCollapse: false
    }
  },
  computed: {
    currentFocus: {
      get() {
        return this.$route.meta.module
      },
      set() {

      }
    },
    parkData() {
      return this.$store.getters.parkData
    }
  },
  mounted() {
    this.menuList = this.clone(mainRouter.children)
    const token = getToken()
    const list = mainRouter.children.filter(item => {
      if (!token) {
        return item.meta.module !== '人才推荐'
      } else {
        return true
      }
    })
    window.addEventListener('resize', this.handleResize)
    this.handleResize() // 初始执行一次回调函数

    // list.splice(5, 0, {
    //   meta: {
    //     module: '人才双选会'
    //   }
    // })
    this.menuList = list
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth
      // console.log(screenWidth)
      // 检查屏幕宽度是否小于等于575px
      if (screenWidth <= 1750) {
        document.getElementById('changediv').style.height = '105px'
        document.getElementById('navigate').style.top = '45px'
        document.getElementById('navigate').style.width = '930px'
        // document.getElementById('navigate').style.width = '60%'
        document.getElementById('navigate').style.left = '80px'
      } else {
        document.getElementById('changediv').style.height = 'auto'
        document.getElementById('navigate').style.top = '0'
        // document.getElementById('navigate').style.width = '49%'
        document.getElementById('navigate').style.width = '930px'
        document.getElementById('navigate').style.left = '290px'
      }
    },
    // handleScroll() {
    //   const ch = document.getElementById('navigate-bar').width()
    //   console.log(ch)
    // },
    clickNavigate(item) {
      const path = item.path
      const nextPath = this.preRoute + path
      if (nextPath === this.$route.path) {
        return false
      }
      this.currentFocus = item.name
      this.$router.push(this.preRoute + path)
    },
    lostFocus() {
      this.islong = false
    },
    gainFocus() {
      this.islong = true
    },
    clearFun() {
      this.islong = false
      document.getElementById('input').blur()
    }
    // toLogin() {
    //   this.$router.push('/login')
    //   removeToken()
    //   removeUserInfo()
    // }
  }
}
</script>

<style lang="scss" scoped>
$navigateHeight: 55px;
// .navigate-bar {
//   height: $navigateHeight;
//   // position: relative;
//       position: sticky;
//     top: 0;
//     z-index: 999;
//         box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
// }
// .navigate-container {
//   position: absolute;
//   left: 0;
//   right: 0;
//   display: flex;
//   justify-content: space-around;
// }
// .navigate {
//   display: flex;
//   justify-content: space-between;
//   position: absolute;
//     left: 16.5%;
//   .navigate-item-parent {
//     padding: 0 25px;
//     cursor: pointer;
//     .navigate-item {
//       height: $navigateHeight;
//       box-sizing: border-box;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       font-size: 16px;
//       color: #000;
//       flex-grow: 1;
//       transition: all 0.3s;
//     }
//   }
//   .navigate-focus,
//   .navigate-item-parent:hover {
//     background-image: url(../../assets/images/navigation-hover.png);
//     background-size: cover;
//     .navigate-item {
//       color: #3055da;
//       transform: scale(1.2);
//     }
//   }
// }
// .navigate-bg {
//   background-size: cover;
//   // background-image: url(../../assets/images/navigation-bg.jpg);
//   background-repeat: no-repeat;
//   background-position: 50% 50%;
//   height: $navigateHeight;
//   position: absolute;
//   left: 0;
//   right: 0;
//   background: #fff;
// }
// .visible-navigate {
//   padding-top: 20px;
//   height: 60px;
//   position: absolute;
//   z-index: 99;
// }
// .page-content {
//   margin-top: $navigateHeight;
// }
// .fade-navigateZ-enter-active,
// .fade-navigate-leave-active {
//   transition: all 0.5s;
// }
// .fade-navigate-enter,
// .fade-navigate-leave-to {
//   opacity: 0;
//   /*transform: translateY(-10px);*/
// }
// .loginInlet{
// position: absolute;
//     right: 3.5%;
//     top: 20px;
//     cursor: pointer;
// }
// .logo{
//   width: 162px;
//   height: 30px;
//       position: absolute;
//     left: 60px;
//     top: 12px;
//   img{
//     width: 100%;
//     height: 30px;
//   }
// }
// .el-input {
//     position: relative;
//     font-size: 14px;
//     display: inline-block;
//     width: 200px;
//     position: absolute;
//         right: 13%;
//     margin-right: 50px;
//         top: 50%;
//     transform: translateY(-50%);
// }
// ::v-deep .el-input--prefix .el-input__inner {
//     padding-left: 30px;
//     height: 30px;
//       border-radius: 8px;
// }
// ::v-deep .el-input__prefix, ::v-deep .el-input__suffix {
//     top: -5px;
// }
// .long{
//   .el-input {
//     width: 350px;
// }
// }
.changediv {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
}
.navigate-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  height: $navigateHeight;
  overflow: hidden;
  // position: relative;
  //   position: sticky;
  // top: 0;
  // z-index: 999;
  // background: #fff;
  //     box-shadow: 0 1px 8px rgb(0 0 0 / 10%);
}
.navigate-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 55%;
  flex-wrap: wrap;
}
.navigate {
  display: flex;
  // justify-content: space-between;
  // width: 43%;
  position: absolute;
  top: 0px;
  z-index: 1000;
  left: 290px;
  font-family: "FZLTHJW--GB1-0";
  .navigate-item-parent {
    padding: 0 25px;
    cursor: pointer;
    .navigate-item {
      height: $navigateHeight;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #000;
      flex-grow: 1;
      transition: all 0.3s;
    }
  }
  .navigate-focus,
  .navigate-item-parent:hover {
    background-image: url(../../assets/images/navigation-hover.png);
    background-size: cover;
    .navigate-item {
      color: #3055da;
      // transform: scale(1.2);
    }
  }
}
.navigate-bg {
  background-size: cover;
  // background-image: url(../../assets/images/navigation-bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: $navigateHeight;
  background: #fff;
}
.visible-navigate {
  padding-top: 20px;
  height: 60px;
  z-index: 99;
}
.page-content {
  margin-top: $navigateHeight;
}
.fade-navigateZ-enter-active,
.fade-navigate-leave-active {
  transition: all 0.5s;
}
.fade-navigate-enter,
.fade-navigate-leave-to {
  opacity: 0;
}
.loginInlet {
  cursor: pointer;
}
.logo {
  // width: 163px;
  // height: 30px;
  margin-right: 50px;
  img {
    // width: 163px;
    height: 30px;
    // height: 100%;
  }
  svg {
    font-size: 180px;
    // width: 100%;
    // height: 100%;
  }
}
.el-input {
  font-size: 14px;
  display: inline-block;
  width: 200px;
  margin-right: 50px;
  background: #fff;
  z-index: 2999;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 30px;
  height: 30px;
  border-radius: 8px;
}
::v-deep .el-input__prefix,
::v-deep .el-input__suffix {
  top: -5px;
}
.long {
  .el-input {
    width: 350px;
  }
}
.flexcencen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
