/**
 * @author Liu Yin
 * @date 2020/1/16
 * @Description: axios基本配置
 */

import axios from 'axios'
import { MessageBox } from 'element-ui'
import router from '../router'
import { getToken, removeToken } from './auth'
import urls from './urls'
import qs from 'qs'
// import main from "../main";

// 是否已经提醒token过期
var isWarnToken = false

const service = axios.create({
  baseURL: urls.BASE_URL,
  withCredentials: true,
  timeout: 60000
})

// 请求拦截
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken()
    }
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 返回拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    res.msg = res.msg || res.message
    if (res.code === 401) {
      if (!isWarnToken) { tokenLoseEfficacy() }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    const result = error.response.data
    if (result.status === 401) {
      if (!isWarnToken) { tokenLoseEfficacy() }
      return Promise.reject(result)
    } else {
      return result
    }
    // return Promise.resolve(error)
  }
)
// token失效
function tokenLoseEfficacy() {
  isWarnToken = true
  MessageBox.confirm('您暂未登录,请先登录', '重新登录', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    isWarnToken = false
    removeToken()
    const path = router.app.$route.path
    const query = router.app.$route.query
    let params = ''
    if (query) {
      for (const key in query) {
        params = params.concat(`&${key}=${query[key]}`)
      }
    }
    location.href = `/login?redirect=${path}${params}`
  }).catch(e => {
    isWarnToken = false
  })
}
// post请求
const $post = (url, data) => new Promise((resolve, reject) => {
  service({
    url: url,
    method: 'post',
    data: data
  }).then((response) => {
    resolve(response, response.code)
  }).catch(err => {
    reject(err)
  })
})
// post form
const $postForm = (url, data) => new Promise((resolve, reject) => {
  service({
    url: url,
    method: 'post',
    data: qs.stringify(data)
  }).then((response) => {
    resolve(response, response.code)
  }).catch(err => {
    reject(err)
  })
})
// get请求
const $get = (url, params, suffixUrl) => {
  if (suffixUrl) {
    suffixUrl.forEach((item, index) => {
      url = url.concat(`/${item}`)
    })
  }
  if (params) {
    for (const i in params) {
      if (!params[i]) {
        delete params[i]
      }
    }
  }
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'get',
      params
    }).then((response) => {
      resolve(response)
    }).catch(err => {
      reject(err)
    })
  })
}

// 下载文件
function $downloadDocument(url, fileName, params) {
  service({
    method: 'get',
    url: url,
    params,
    responseType: 'blob'
  }).then(res => {
    const blob = new Blob([res], { type: 'application/download' })
    const objectUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = fileName
    a.href = objectUrl
    a.click()
  }
  ).catch(e => {
    console.error(e)
    return e
  })
}

export {
  $post,
  $get,
  $postForm,
  $downloadDocument
}
