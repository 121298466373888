/**
 * @author Liu Yin
 * @date 2020/1/16
 * @Description: 管理接口路径
 */

const urls = {
  BASE_URL: '/api',
  personPlatform: '/mutual-portal/login',
  // 获取校验码
  verifyCode: '/login/verifyCode.jpg',
  // 登录
  login: '/login/login',
  // 资讯列表
  informationList: '/information/info/door/pageQuery',
  // 资讯详情
  informationDetail: '/information/info/door/getDetail',
  // 添加点击量
  informationAddClick: '/information/info/door/addClicks',
  // 员工注册
  employeeSave: '/enter/employee/save',
  // 部门列表
  deptList: '/sys/dept/list',
  // 详情
  personInfo: '/portal/resumeRecommend/details',
  // 获取字典数据
  dicValues: '/sys/dictionary/getDictValues',
  /* 智慧人才库*/
  // 列表  加密
  personListDoor: '/person/info/pageQueryDoor',
  // 列表  不加密
  personList: '/person/info/pageQuery',
  // 新增修改人员基本信息
  savePerson: '/person/info/savePerson',
  // 新增编辑工作经历
  saveWork: '/person/info/saveWork',
  // 删除工作经历
  removeWork: '/person/info/removeWork',
  // 新增编辑项目经验
  saveProject: '/person/info/saveProject',
  // 删除项目
  removeProject: '/person/info/removeProject',
  // 新增编辑学习经历
  saveEducation: '/person/info/saveEducation',
  // 删除学习经历
  removeEducation: '/person/info/removeEducation',
  // 新增编辑奖励信息
  savePrize: '/person/info/savePrize',
  // 删除奖励
  removePrize: '/person/info/removePrize',
  // 新增编辑证书
  saveCertificate: '/person/info/saveCertificate',
  // 删除证书
  removeCertificate: '/person/info/removeCertificate',
  // 文件上传
  uploadFiles: '/uploadFiles',
  // 注册
  register: '/park/enter/register',
  // 校验用户名
  checkUsername: '/sys/people/checkUsername',
  // 获取用户信息
  getLoginUser: '/login/getLoginUser',
  // 测评详情
  testDetail: '/assess/info/getDetail',
  // 测评库列表
  assessList: '/portal/assess/list',
  // 获取领域上级类别
  atcuList: '/sys/domain/listDoor',
  // 开始测评
  startAppraisal: '/assess/answer/startExam',
  // 保存答题
  saveItemQuestion: '/assess/answer/saveSheet',
  // 结束测评
  finishAppraisal: '/assess/answer/endExam',
  // 课程管理
  courseList: '/portal/course/list',
  // 获取章列表
  getChapterList: '/course/chapter/page',
  // 节列表
  sectionList: '/course/section/page',
  // 收藏简历
  collectResume: '/person/collection/save',
  // 获取岗位列表
  postList: '/station/info/listMyStation',
  // 添加到岗位
  postSave: '/person/post/save',
  // 上级部门列表
  supDeptList: '/sys/dept/list',
  // 资源共享列表
  shareList: '/portal/share/page',
  // 资源共享详情
  shareDetail: '/portal/share/get',
  // 获取园区信息
  getParkInfo: '/park/info/getDoor',
  // 获取课程详情
  getCourseId: '/portal/course/get',
  // 获取测评信息
  talentDetail: '/futurelab/info/talentDetail',
  // 退出登录
  logout: '/login/logout',
  // 个人测评详情
  personAssessDetail: '/assess/person/get',
  // 追踪列表
  getTraceList: '/person/comm/page/1/100000',
  // 保存追踪
  traceSave: '/person/comm/save',
  // 查询企业详情
  getEnterpriseInfo: '/park/enter/get',
  // 历史测评报告
  fetchLastFiveRecords: '/assess/person/fetchLastFiveRecords',
  // 课程点击方法
  addClickCount: '/portal/course/click',
  // 课程点击方法
  assessClickCount: '/portal/assess/click',
  // 招聘岗位列表
  postRecommendList: '/portal/post/list',
  // 企业列表
  companyRecommendList: 'portal/getEnterprisesLogo',
  // 企业详情
  enterDetail: '/park/enter/homePage/get',
  // 详情
  stationDetail: '/station/info/homePage/get',
  // 在线简历提交
  resumeSubmitOnline: '/portal/submitOnline',
  // 轮播图列表
  bannerList: '/portal/banner',
  // 证书
  certificateList: '/portal/certificate/page',
  // 首页数据
  portalhome: '/portal/home',
  // 企业简历列表
  enterSettledList: '/portal/resumeRecommend/page',
  // 合作伙伴公司列表
  partnersList: '/portal/partners/page',
  // 合作商机企业列表
  corpList: '/portal/corp/getCorpList',
  // 合作商机详情
  corpDetails: '/portal/corp/getCorpDetails',
  // 门户首页资料库
  libraryHome: '/portal/library/home',
  // 门户查看更多
  librarypage: '/portal/library/page',
  // 资料库文件下载量
  incrementDownloadCount: '/portal/incrementDownloadCount',
  // 下载资料库文件
  libraryDownloadFile: '/library/downloadFile',
  // 下载资料权限查询
  limitation: '/library/limitation',
  // 获取一站式管理内容
  getEmployment: '/portal/getEmployment',
  // 获取培训/就业管理内容
  getProvision: '/portal/provision/list',
  // 产品目录库查询
  productPage: '/portal/productCatalog/list',
  // 讲师列表查询
  teacherList: '/portal/teacher/list',
  // 讲师列表详情查询
  teacherGet: '/portal/teacher/get'
}

export default urls
