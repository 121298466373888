
const state = {
  scrollY: 0,
  // 1园区登录  2企业登录
  loginType: '2',
  // 园区数据
  parkData: {},
  // 个人信息
  userInfo: {}
}

const mutations = {
  SET_SCROLL_Y: (state, scrollY) => {
    state.scrollY = scrollY
  },
  SET_LOGIN_TYPE: (state, loginType) => {
    state.loginType = loginType
  },
  SET_PARK_DATA: (state, parkData) => {
    state.parkData = parkData
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  }
}

const actions = {
  setScrollY({ commit }, scrollY) {
    commit('SET_SCROLL_Y', scrollY)
  },
  setLoginType({ commit }, loginType) {
    commit('SET_LOGIN_TYPE', loginType)
  },
  setParkData({ commit }, parkData) {
    commit('SET_PARK_DATA', parkData)
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
