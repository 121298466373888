import Cookies from 'js-cookie'

const portalTokenKey = 'token'
const storageKey = 'user'

const storage = JSON.parse(localStorage.getItem(storageKey))

export function getToken() {
  return Cookies.get(portalTokenKey)
}

export function setToken(token) {
  return Cookies.set(portalTokenKey, token)
}

export function removeToken() {
  return Cookies.remove(portalTokenKey)
}
// 存储园区token
export function setParkToken(token) {
  // return Cookies.set(parkTokenKey, token)
}
// 存储企业token
export function setEnterpriseToken(token) {
  //  return Cookies.set(enterpriseTokenKey, token)
}

// 存储用户信息
export function setUserInfo(userInfo, token) {
  const peopleType = userInfo.peopleType
  if (peopleType === '1') {
    // 园区系统用户
    if (token)setParkToken(token)
  }
  if (peopleType === '2') {
    // 企业用户
    if (token)setEnterpriseToken(token)
  }
  localStorage.setItem(storageKey, JSON.stringify(userInfo))
}
// 获取个人用户信息
export function getUserInfo() {
  const storage = JSON.parse(localStorage.getItem(storageKey))
  if (storage) {
    return storage
  }
}
// 移除个人信息
export function removeUserInfo() {
  localStorage.removeItem(storageKey)
}
// 获取个人菜单权限
export function getMenuCode() {
  const storage = JSON.parse(localStorage.getItem(storageKey))
  if (storage) {
    return storage.menus
  }
}
// 判断是否有操作权限
export function havePermission(code) {
  if (!code) {
    return true
  }
  if (storage) {
    const codeList = storage.buttonCodeList
    const isExist = codeList.some(item => {
      if (item === code) {
        return true
      }
    })
    return isExist
  }
  return false
}
