<!--
   User: Liu Yin
   Date: 2020/3/5
   Description:
 -->
<template>
  <article class="bottom">
    <section class="content-box" style="display: flex;justify-content: space-between;">
      <article class="bottom-left">
        <div style="display: flex;">
          <div class="linkfont">链接:</div>
          <div class="linkDiv">
            <div v-for="(item,index) in parkData.links" :key="index" class="web-logo"><img :src="item.linkImage" alt="" @click="handleClickLink(item)"></div>
          </div>

        </div>
        <!-- <div style="height: 40px">
          <span>资源链接:</span>
          <img v-for="(item,index) in parkData.links" :key="index" class="web-logo" :src="item.linkImage" alt="" @click="handleClickLink(item)">
        </div> -->

      </article>
      <section>
        <div style="display:flex">
          <div class="introduce-text">主办单位:</div>
          <div class="introduce-text">
            <p v-for="(item,index) in hostUnit" :key="index">{{ item.value }}</p>
            <!-- <p>{{ parkData.parkOrganizer }}</p>
            <p>苏州市中软卓越信息技术有限公司</p> -->
          </div>
        </div>
        <p class="introduce-text">入驻热线:{{ parkData.parkMobile }}</p>
        <p class="introduce-text">园区地址:{{ parkData.parkAddress }}</p>
        <p class="introduce-text">支持单位:{{ parkData.parkSupport }}</p>
        <!-- <p style=" margin-bottom: 12px;">主办单位</p>
          <p style=" color: #9c9da1; font-size: 12px; line-height: 24px;">{{ parkData.parkOrganizer }}</p>
          <p style=" color: #9c9da1; font-size: 12px; line-height: 24px;"></p>
          <p style=" margin-bottom: 12px;margin-top: 40px;">支持单位</p>
          <p style=" color: #9c9da1; font-size: 12px; line-height: 24px;">{{ parkData.parkSupport }}</p>
          <p class="introduce-text">入驻热线:{{ parkData.parkMobile }}</p>
          <p class="introduce-text">园区地址:{{ parkData.parkAddress }}苏州高新区浒墅关经开区人才市场</p> -->
      </section>
      <section class="bottom-right">
        <div class="bottom-right-content">
          <p class="right-title">服务平台(1)</p>
          <img :src="getImg(1,parkData.parkOfficial)" class="bottom-qr" @error="imgError">
        </div>
        <div class="bottom-right-content">
          <p class="right-title">服务平台(2)</p>
          <img :src="getImg(2,parkData.officialAccount)" class="bottom-qr" @error="imgError">
        </div>
      </section>
    </section>
    <section class="bottom-footer">
      <div class="footer-box">
        <a class="footer-box-title">
          <p>Copyright© 2019-2023北京中软国际教育科技股份有限公司 版权所有 保留一切权利</p>
        </a>
        <a class="footer-box-title" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <p>苏ICP备2022001419号-1</p>
        </a>
        <a class="footer-box-title" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo">
          <img src="../assets/images/filings.png">
          <p style="margin-right:0">苏公网安备 32050502001190号</p>
        </a>
        <!-- <a v-if="filingNumber" class="footer-box-title" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <p>{{ filingNumber }}</p>
        </a>
        <a v-if="publicNetwork" class="footer-box-title" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo">
          <img src="../assets/images/filings.png">
          <p style="margin-right:0">{{ publicNetwork }}</p>
        </a> -->
      </div>
    </section>
  </article>
</template>

<script>
import default1 from '../assets/images/default1.png'
import default2 from '../assets/images/default2.png'
import { DomainName } from '../utils/dict'
export default {
  data() {
    return {
      DomainName,
      // 公网安备
      publicNetwork: null,
      // 备案号
      filingNumber: null,
      hostUnit: []
    }
  },
  computed: {
    parkData() {
      return this.$store.getters.parkData
    }
  },
  created() {
    this.getDetailData()
  },
  mounted() {
    console.log(window.location.hostname, 'window.location.hostname')
    const host = window.location.hostname
    if (host.includes(DomainName.YZ_A) || host.includes(DomainName.YZ_B)) {
      this.publicNetwork = null
      this.filingNumber = '苏ICP备2023013475号-1'
    } else if (host.includes(DomainName.CQ_A) || host.includes(DomainName.CQ_B)) {
      this.publicNetwork = null
      this.filingNumber = '渝ICP备2023005610号-1'
    } else if (host.includes(DomainName.WH_A) || host.includes(DomainName.WH_B)) {
      this.publicNetwork = null
      this.filingNumber = null
    } else if (host.includes(DomainName.LZ_A) || host.includes(DomainName.LZ_B)) {
      this.publicNetwork = '甘公网安备 62010202004221号'
      this.filingNumber = '陇ICP备2023001617号-1'
    } else {
      this.publicNetwork = '苏公网安备 32050502001190号'
      this.filingNumber = '苏ICP备2022001419号-1'
    }
  },
  methods: {
    // 获取园区信息
    getDetailData() {
      this.$get(this.urls.getParkInfo, {}, [1]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          const hostUnitList = data.parkOrganizer.split(',')
          hostUnitList.forEach(item => {
            this.hostUnit.push({ value: item })
          })
        } else {
          this.$message.error('查询失败')
        }
      }).catch(() => {
        this.$message.error('查询失败')
      })
    },
    // 加默认封面
    getImg(index, item) {
      if (item) return item
      return index === 1 ? default1 : default2
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      // img.src = backgroundImg
      img.onerror = null // 防止闪图
    },
    toMain() {
      location.href = '/'
    },
    handleClickLink(item) {
      window.open(item.webSite)
    }
  }
}
</script>
<style lang="scss" scoped>
.bottom {
  background: rgb(237, 238, 239);
  padding-top: 40px;
  width: 100%;
  .content-box {
    // width: 1440px;
    width: 80%;
    margin: 0 auto;
  }
  .bottom-right {
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 240px;
    .right-title {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    .bottom-right-content {
      margin-bottom: 30px;
      text-align: center;
    }
    .bottom-qr {
      height: 90px;
      width: 90px;
      border-radius: 4px;
    }
  }
  .bottom-footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 49px;
    font-size: 14px;
    justify-content: center;
    background-color: #000000;
    .footer-box {
      width: 100%;
      // padding: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: white;
      }
      .footer-box-title {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
        p {
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0px 40px 0px 0px;
        }
        img {
          float: left;
          padding-right: 5px;
        }
      }
    }
  }
}

.web-logo {
  width: 145px;
  height: 55px;
  // display: inline-block;
  margin-bottom: 10px;
  margin-left: 33px;
  // border: 1px solid #dedede;
  display: flex;
  align-items: center;
  // margin: 10px 30px;
  // padding: 10px;
  margin: 4px;
  padding: 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.introduce-text {
  color: rgb(85, 86, 86);
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 30px;
}
.linkfont {
  display: inline-block;
  min-width: 37px;
  margin: 10px;
  vertical-align: top;
}
.linkDiv {
  max-width: 470px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
