/**
 * @author Liu Yin
 * @date 2020/2/11
 * @Description: 列表页面混入
 */
export default {
  data() {
    return {
      // 是否展示新增编辑弹窗
      showDialog: false,
      // 弹窗标题
      dialogTitle: '',
      // table列表数据
      tableData: [],
      // 查询总条数
      total: 0,
      // 条目数据
      rowData: null,
      // 条目ID
      rowId: null,
      // 当前页数
      currentPage: 1,
      // 查询条数
      size: 10,
      // 搜索条件
      searchForm: {},
      // 是否展示详情
      showDetail: false
    }
  },
  methods: {
    // 新增
    handleAdd() {
      this.dialogTitle = '新增'
      this.rowData = null
      this.showDialog = true
    },
    // 编辑
    handleEdit(row) {
      this.dialogTitle = '编辑'
      this.rowData = this.clone(row)
      this.showDialog = true
    },
    // 翻页
    handleCurrentChanges(val) {
      this.currentPage = val
      this.requestData()
    },
    // 删除提醒
    warningRemove(url, id) {
      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleRemove(url, id)
      }).catch(e => {

      })
    },
    // 删除
    handleRemove(url, id) {
      this.toggleLoading(true)
      this.$post(url, { id: id }).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.$message.success('删除成功')
          this.requestData()
        } else {
          this.$message.error(result.message)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    handleCurrentChange() {
      this.requestData()
    },
    // 执行搜索
    handleSearch() {
      this.currentPage = 1
      this.requestData()
    },
    // 清空搜索条件
    clearSearchForm() {
      this.searchForm = {}
      this.requestData()
    },
    // 存储当前滚动位置
    setScrollPosition() {
      const scrollY = document.body.scrollTop
      // const scrollY = document.getElementById('app').scrollTop
      this.$store.dispatch('app/setScrollY', scrollY)
      // document.getElementById('app').scrollTop = 0
      document.body.scrollTop = 0
    },
    // 滚动到指定位置
    scrollToPosition() {
      const scrollY = this.$store.getters.scrollY
      this.$nextTick(() => {
        document.body.scrollTop = scrollY
        // document.getElementById('app').scrollTop = scrollY
        // window.scrollTo(0, scrollY)
      })
    }
  }
}
