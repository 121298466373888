<template>
  <div>
    我的课程
  </div>
</template>
<script>
export default {
  name: 'MyCourse',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
</style>
