<template>
  <div id="app">
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive> -->
      <router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.module" />
      <!-- </keep-alive> -->
    </transition>
    <transition name="fade-transform" mode="out-in">
      <router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.module" />
    </transition>

  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { getToken, getUserInfo, setToken, setUserInfo } from './utils/auth'
import Cookies from 'js-cookie'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydownListener)
  },
  mounted() {
    const href = window.location.href
    const arr = href.split('peopleType=')
    const data = this.getUrlKey('token')
    if (data != null) {
      Cookies.set('token', decodeURIComponent(data))
    }
    // if (arr.length > 1) {
    //   if (getToken()) {
    //     this.requestUserInfo()
    //   }
    // } else
    if (getToken()) {
      this.requestUserInfo()
    }
    document.addEventListener('keydown', this.keydownListener)
  },
  methods: {
    keydownListener(event) {
      if (event.key === 'Escape') {
        this.toggleLoading(false)
      }
    },
    getQueStr(url, ref) {
      var str = url.substr(url.indexOf('?') + 1)
      if (str.indexOf('&') !== -1) {
        var arr = str.split('&')
        for (const i in arr) {
          if (arr[i].split('=')[0] === ref) { return arr[i].split('=')[1] }
        }
      } else {
        return url.substr(url.indexOf('=') + 1)
      }
    },
    // 获取用户信息
    requestUserInfo() {
      this.$get(this.urls.getLoginUser).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          setUserInfo(data)
          this.$store.dispatch('app/setUserInfo', data)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }
  }
}
</script>

<style>
body.el-loading-parent--hidden {
  overflow: unset !important;
}
</style>
