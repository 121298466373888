import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from './store'
import '@/icons'
import './styles/index.scss'
// import 'swiper/dist/css/swiper.css'
import 'swiper/swiper-bundle.css'
import global from './utils/global'
// eslint-disable-next-line no-unused-vars
// import $ from 'jquery'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

import Vant from 'vant'
import 'vant/lib/index.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import moment from 'vue-moment'
Vue.use(moment)

Vue.use(Vant)

Vue.use(global)
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
