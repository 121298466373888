/**
 * @Author Liu Yin
 * @Date 2021/10/29
 * @Description: 数据字典
 * 如果需要根据字典的value值转换为对应的名称,后面需要加上NAMES字段  详情参考./convert.js
 */
/**
 * 角色类型
 */
export const PeopleType = {
  PARK: '1', // 园区
  ENTERPRISE: '2', // 企业
  COMMON: '3', // 普通用户
  NAMES: ['园区', '企业', '普通用户']
}
/**
 * 题目类型
 */
export const QuestionType = {
  SINGLE_CHOICE: '1',
  MULTIPLE_CHOICE: '2',
  JUDGE: '3',
  FILL_BLANK: '4',
  BRIEF_ANSWER: '5',
  NAMES: ['单选', '多选', '判断', '填空', '简答']
}

// 共享状态
export const ShareStatus = {
  UN_SHARE: 0,
  REVIEW_PENDING: 1,
  FINISH_SHARE: 2,
  NOT_PASS: 3,
  NAMES: ['未共享', '待审核', '已共享', '未通过']
}

// 发布状态
export const PublishStatus = {
  UN_PUBLISH: 0,
  PUBLISHED: 1,
  NAMES: ['未发布', '已发布']
}

// 批阅状态
export const ReviewStatus = {
  UN_REVIEW: '0',
  FINISHED: '1',
  NAMES: ['批阅中', '已批阅']
}

// 测评来源
export const EvaluationSource = {
  SOURCE_YPS: 'yps',
  SOURCE_LOCAL: 'local',
  SOURCE_DISC: 'disc',
  NAMES: ['易普思测评', '自建测评', 'DISC测评']
}

// banner
export const Banner = {
  BANNER_HOME: 'Home',
  BANNER_INFORMATION: 'Information',
  BANNER_TRAINING: 'Training',
  BANNER_JOBPOSITION: 'JobPosition',
  BANNER_CLASSROOM: 'Classroom',
  BANNER_ASSESSMENT: 'Assessment',
  BANNER_PARTNERS: 'Partners',
  BANNER_DATABASE: 'DataBase',
  NAMES: ['首页', '资讯专栏', '培训服务', '岗位推荐', '企业学院', '人才测评', '合作伙伴', '资料库']
}

// 域名
export const DomainName = {
  SZ_A: '123.60.104.49',
  SZ_B: 'rencai-sz.com',
  YZ_A: '114.132.158.249',
  YZ_B: 'rencai-yz.com',
  CQ_A: '60.204.133.30',
  CQ_B: 'www.cqitts.com',
  WH_A: '121.36.68.12',
  WH_B: 'www.wuhan-rencai.com',
  LZ_A: '117.157.110.142',
  LZ_B: 'www.lzszrcggfwpt.com',
  NAMES: ['苏州', '苏州', '扬州', '扬州', '重庆', '重庆', '武汉', '武汉', '兰州', '兰州']
}
